<template>
  <div>
    <div class="data-form-background" @click="ctrlback"></div>
    <div
      style="
        left: 41%;
        background-color: white;
        z-index: 3;
        position: absolute;
        padding: 20px 20px;
        border-radius: 5px;
      "
    >
      <div>
        <h2
          @click="ctrlback"
          style="
            margin-top: 5px;
            padding-bottom: 10px;
            border-bottom: solid 1px lightgrey;
          "
        >
          <i class="far fa-arrow-alt-circle-left" style="cursor: pointer"></i>
          {{ title }}
        </h2>
      </div>
      <!-- <h4>
        {{ $moment(event.date).format('DD/MM/YYYY') }}
      </h4> -->
      <div>
        <label align="center"> Nombre d'intervention maximum </label>
        <input
          style="
            padding: 8px;
            margin-top: 1px;
            width: 50%;
            border-style: none;
            border-radius: 5px;
            font-size: 15px;
            background-color: rgb(0, 0, 0, 0.1);
          "
          type="number"
          min="0"
          max="10"
          v-model="temporaryEvents['nbrEventMax']"
        />
      </div>
      <div style="">
        <button
          class="fbutton"
          style="float: right; margin-left: 5px; margin-top: 15px"
          @click="ctrlsave"
        >
          valider
        </button>
        <button
          class="fbutton"
          style="background-color: red; float: right; margin-top: 15px"
          @click="ctrlback"
        >
          Annuler
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      temporaryEvents: {},
      // form: [
      //   {
      //     label: "test",
      //     type: "select",
      //     inputName: "title",
      //   },
      // ],
    };
  },
  props: {
    title: {},
    event: {},
    fxback: {},
    fxsave: {},
  },
  mounted() {
    if (this.event)
      this.temporaryEvents = JSON.parse(JSON.stringify(this.event));
  },
  methods: {
    ctrlback: function () {
      this.fxback();
    },
    ctrlsave: function () {
      if (this.temporaryEvents['nbrEventMax']) this.fxsave(this.temporaryEvents);
      else this.fxback();
    },
    ctrldelete: function () {
      this.fxdelete(this.temporaryEvents);
    },
  },
};
</script>

import Vue from 'vue';
var CryptoJS = require("crypto-js");

class DataAPI {
    constructor() {
        // this.URL = "https://test.limoges.accen.me/api";
        this.URL = "https://limoges.accen.me/api";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    add_user_in_historique(user, ip, callback) {
        let datas = { USER: user, IP: ip }
        Vue.http.post(this.URL + '/v1/add_user_in_historique', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

    AGENDA_sup_rdv_materiel(id, callback) {
        let datas = { id }
        Vue.http.post(this.URL + '/v1/AGENDA_sup_rdv_materiel', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

    AGENDA_maj_rdv_materiel(id, callback) {
        let datas = { id }
        Vue.http.post(this.URL + '/v1/AGENDA_maj_rdv_materiel', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

    user_token_is_valide(token, callback) {
        let datas = { token }
        this.encrypt(datas)
        Vue.http.post(this.URL + '/v1/user_token_is_valide', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

    user_connection(user, password, callback) {
        let datas = { user, password }
        Vue.http.post(this.URL + '/v1/user_connection', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

    send_email(recordId, body, event, date, callback) {
        let datas = { recordId, body, event, date }
        Vue.http.post(this.URL + '/v1/send_email', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

    creat_email_verification(body, callback) {
        let datas = { body }
        Vue.http.post(this.URL + '/v1/creat_email_verification', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

    get_client_email_verification(id, callback) {
        let datas = { id }
        Vue.http.post(this.URL + '/v1/get_client_email_verification', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }


    get_client_info_by_id(id, callback) {
        let datas = { id }
        Vue.http.post(this.URL + '/v1/get_client_info_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }


    delete_calendar_setting(setting, id, callback) {
        let datas = { setting, id }
        Vue.http.post(this.URL + '/v1/delete_calendar_setting', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

    edit_calendar_setting(setting, id, callback) {
        let datas = { setting, id }
        Vue.http.post(this.URL + '/v1/edit_calendar_setting', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

    set_calendar_setting(setting, callback) {
        let datas = { setting }
        Vue.http.post(this.URL + '/v1/set_calendar_setting', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

    get_calendar_settings(start, end, callback) {
        let datas = { start, end }
        Vue.http.post(this.URL + '/v1/get_calendar_settings', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

    get_events(start, end, callback) {
        let datas = { start, end }
        Vue.http.post(this.URL + '/v1/get_events', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

    create_event(event, callback) {
        let datas = { event }
        Vue.http.post(this.URL + '/v1/create_event', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

    edit_event(event, id, callback) {
        let datas = { event, id }
        Vue.http.post(this.URL + '/v1/edit_event', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

}

export default new DataAPI()
<template>
  <div v-if="show" align="center" style="padding-top: 25vh">
    <div
      style="
        max-width: 600px;
        width: 100%;
        background-color: #eeeeee;
        border-radius: 5px;
        box-shadow: 0 0 5px 2px grey;
        padding-bottom: 15px;
        position: relative;
      "
    >
      <div
        v-if="isLoading"
        style="
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgb(0, 0, 0, 0.2);
          z-index: 1;
        "
      >
        <loader align="center" style="margin-top: 13%" />
      </div>

      <div
        style="
          background-color: #424242;
          padding: 1px;
          padding-left: 20px;
          margin-bottom: 15px;
        "
        align="left"
      >
        <h2 style="color: white">Dashboard Etat des lieux</h2>
      </div>
      <div style="padding-top: 1vh; width: 100%">
        <div style="width: 100%" align="center">
          <font-awesome-icon
            class="icon"
            icon="user"
            style="font-size: 25px; color: #424242"
          />
          <input
            class="input_form"
            type="text"
            style="width: 80%"
            placeholder="Nom de compte"
            v-model="user"
          />
        </div>
        <div style="width: 100%">
          <font-awesome-icon
            icon="lock"
            class="icon"
            style="font-size: 25px; color: #424242"
          />
          <input
            class="input_form"
            type="password"
            style="width: 80%"
            placeholder="Mot de passe"
            v-model="password"
          />
        </div>
      </div>
      <div style="color: red; padding: 5px">
        {{ errorMsg }}
      </div>
      <div style="padding-top: 10px" align="center">
        <button class="login_btn" @click="connection()">Se connecter</button>
      </div>
    </div>
  </div>
</template>

<script>
import engine from "@/core/data/DataAPI.js";
import loader from "@/components/LoadingForm";

export default {
  data() {
    return {
      show: false,
      isLoading: false,
      user: undefined,
      password: undefined,
      errorMsg: undefined,
    };
  },
  components: {
    loader,
  },
  mounted() {
    this.userIsConnected();
  },
  methods: {
    userIsConnected() {
      let token = localStorage.getItem("token");
      let that = this;
      engine.user_token_is_valide(token, function (data) {
        if (data.code === 0) {
          that.$router.push("/");
        } else {
          that.show = true;
        }
      });
    },
    set_connection_historique() {
      let that = this;
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          engine.add_user_in_historique(that.user, ip, function () {});
        });
    },
    connection() {
      if (
        this.user === undefined ||
        this.user.lenght === 0 ||
        this.password === undefined ||
        this.password.lenght === 0
      ) {
        this.errorMsg = "Identifiant ou mot de passe incorrecte";
      } else {
        let that = this;
        this.errorMsg = undefined;
        this.isLoading = true;
        engine.user_connection(this.user, this.password, function (data) {
          if (data.code === 0) {
            localStorage.setItem("token", data.data.token);
            that.$router.push("/");
            that.set_connection_historique();
          } else {
            that.errorMsg = "Identifiant ou mot de passe incorrecte";
          }
          that.isLoading = false;
        });
      }
    },
    tokenIsValide() {
      let token = localStorage.getItem("token");
      engine.user_token_is_valide(token, function () {});
    },
  },
};
</script>

<style>
.input_form {
  margin: 10px;
  padding: 10px 10px;
  border-radius: 5px;
  border-style: none;
  border: solid 1px grey;
}

.login_btn {
  padding: 10px;
  border-radius: 5px;
  border-style: none;
  border: solid 1px grey;
  cursor: pointer;
}

.login_btn:hover {
  box-shadow: 0 0 1px 1px grey;
}

.icon {
  position: absolute;
  margin-top: 15px;
  margin-left: -20px !important;
  /* top: 50vh; */
}
</style>
<template>
  <div>
    <div class="data-form-background" @click="ctrlback"></div>
    <div class="data-form">
      <div
        v-if="isLoading"
        style="
          position: absolute;
          height: 100%;
          background-color: rgb(0, 0, 0, 0.3);
          width: 100%;
          z-index: 1;
        "
      >
        <loader align="center" style="margin-top: 35%" />
      </div>
      <div style="padding: 0px 20px">
        <div class="line" style="border-bottom: solid 1px var(--C3)">
          <div style="padding-left: 20px">
            <h2>
              <div @click="ctrlback">
                {{ title }}
              </div>
                <!-- v-if="isEditing === true && !displayButton" -->
              <button
                v-if="isEditing === true"
                style="
                  float: right;
                  margin-top: -27px;
                  font-size: 20px;
                  border-radius: 30%;
                  border-style: none;
                  cursor: pointer;
                  background-color: var(--C2);
                  color: white;
                "
                @click="localDisabled = !localDisabled"
              >
                ✎
              </button>
            </h2>
          </div>
        </div>
        <div
          class="line"
          v-for="elem in form"
          :key="elem.label"
          style="width: 100%"
        >
          <div class="form-input" v-if="elem.type == 'text'">
            <label align="left">
              {{ elem.label }}
            </label>
            <input
              type="text"
              v-model="temporaryEvents[elem.inputName]"
              :disabled="localDisabled"
              @focusout="verifVeloNumber(elem, temporaryEvents[elem.inputName])"
            />
            <label
              style="margin-left: 10px; font-weight: normal; font-size: 10px"
              v-if="elem.inputName == 'veloNumber'"
              align="left"
            >
              <div v-if="temporaryEvents['clientInformation']">
                <div style="float: left; margin-right: 5px">
                  Client :
                  {{ temporaryEvents["clientInformation"]["Clients::Société"] }}
                </div>
                <div v-if="emailSended !== undefined">
                  <div v-if="emailSended">( Email : Envoyé)</div>
                  <div v-else>( Email : Pas envoyé)</div>
                </div>
                <!-- {{ clientInformation["fieldData"]["Clients::Société"] }} -->
              </div>
              <div v-else>
                <div
                  v-if="
                    temporaryEvents[elem.inputName] &&
                    temporaryEvents[elem.inputName].length !== 0
                  "
                >
                  Aucune Société associé à ce numéro de vélo
                  <div style="text-transform: none !important">
                    ( Enregistrer pour mettre à jour les informations )
                  </div>
                </div>
              </div>
            </label>
            <!-- <div v-if="elem.inputName == 'veloNumber'">
            {{ elem.inputName }}
            dd
          </div> -->
          </div>
          <div
            class="form-input"
            v-if="elem.type == 'radio' && event.title !== null"
          >
            <label align="left">
              {{ elem.label }}
            </label>
            <div>
              <div style="float: left; width: 30%">
                <input
                  type="radio"
                  name="drone"
                  value="Non Réalisé"
                  v-model="temporaryEvents[elem.inputName]"
                  checked
                  :disabled="localDisabled"
                />
                <label>Non Réalisé</label>
              </div>

              <div style="float: left; width: 30%">
                <input
                  type="radio"
                  name="drone"
                  value="Réalisé"
                  v-model="temporaryEvents[elem.inputName]"
                  :disabled="localDisabled"
                />
                <label for="dewey">Réalisé</label>
              </div>

              <div style="float: left; width: 30%">
                <input
                  type="radio"
                  name="drone"
                  value="Client Absent"
                  v-model="temporaryEvents[elem.inputName]"
                  :disabled="localDisabled"
                />
                <label for="louie">Client Absent</label>
              </div>
            </div>
          </div>
          <div v-if="elem.type == 'date'" class="form-input form-date">
            <label align="left">
              {{ elem.label }}
            </label>
            <div>
              <Datepicker
                v-model="temporaryEvents[elem.inputName]"
                monday-first
                format="dd MMMM yyyy"
                input-class="form-input"
                :disabled-dates="disabledDates"
                :language="fr"
                :disabled="localDisabled"
              />
            </div>
          </div>
          <div
            v-if="elem.type == 'select'"
            class="form-input"
            style="margin-top: 15px; padding-top: 0px"
          >
            <label align="left">
              {{ elem.label }}
            </label>
            <select
              v-model="temporaryEvents[elem.inputName]"
              style="width: 100%"
              :disabled="isEditing"
            >
              <option
                :value="undefined"
                disabled
                v-if="temporaryEvents[elem.inputName] === undefined"
              >
                {{ getCategoryKeys[0] }}
              </option>
              <option
                v-for="(value, idx) in getCategoryKeys"
                :key="idx"
                :style="{ display: itsFirst(temporaryEvents[elem.inputName], idx) }"
              >
                {{ value }}
              </option>
            </select>
          </div>

          <div v-if="elem.type == 'time'" style="width: 50%; float: left">
            <div class="form-input">
              <label align="left">
                {{ elem.label }}
              </label>
              <div style="padding: 0px 2px" v-if="timeError[elem.inputName]">
                <VueTimepicker
                  :style="{
                    border:
                      'solid  1px ' +
                      timeError[elem.inputName]['color'] +
                      ' !important',
                    'border-radius': '5px',
                  }"
                  v-model="temporaryEvents[elem.inputName]"
                  :input-class="['form-time']"
                  hide-clear-button
                  input-width="100%"
                  :disabled="localDisabled"
                  manual-input
                  hide-dropdown
                  @input="timePickerEror($event, elem.inputName)"
                />
                <div style="color: red">
                  <div style="padding-top: 13px" align="left">
                    {{ timeError[elem.inputName].msg }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="localDisabled" align="right">
          <button
            class="fbutton"
            style="background-color: lightgrey; margin-right: 10px"
          >
            Impossible de modifier cette évenement
          </button>
          <button class="fbutton" style="margin-right: 10px" @click="ctrlback">
            Fermer
          </button>
        </div>
        <div v-else>
          <div v-if="allFielsAreFilled && timeIsValide" align="right">
            <button
              class="fbutton"
              style="background-color: red; margin-right: 10px"
              @click="ctrldelete()"
            >
              Supprimer l'évenement
            </button>
            <button @click="ctrlsave" class="fbutton">Enregistrer</button>
          </div>
          <div v-else align="right">
            <button
              class="fbutton"
              style="background-color: red; margin-right: 10px"
              @click="ctrldelete()"
            >
              Supprimer l'évenement
            </button>
            <button
              @mouseover="hover = true"
              @mouseleave="hover = false"
              class="fbutton"
              style="background-color: lightgrey; cursor: not-allowed"
            >
              <div v-if="hover">
                {{ validationMessage }}
              </div>
              <div v-else>Enregistrer</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loader from "@/components/LoadingForm";
import engine from "@/core/data/DataAPI.js";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import { fr } from "vuejs-datepicker/dist/locale";

export default {
  data() {
    return {
      emailSended: undefined,
      clientInformation: undefined,
      localDisabled: false,
      isLoading: false,
      hover: false,
      validationMessage: "",
      temporaryEvents: {},
      timeError: {},
      date: {},
      fr: fr,
      isEditing: false,
      displayButton: false,
      form: [
        {
          label: "Catégorie",
          type: "select",
          inputName: "category",
        },
        {
          label: "Description",
          type: "text",
          inputName: "title",
        },
        {
          label: "Numéro du vélo",
          type: "text",
          inputName: "veloNumber",
        },
        {
          label: "Etats du rdv",
          type: "radio",
          inputName: "state",
        },
        {
          label: "Date",
          type: "date",
          inputName: "dateStart",
        },
        // {
        //   label: "Date de fin",
        //   type: "date",
        //   inputName: "dateEnd",
        // },
        {
          label: "Heure de début",
          type: "time",
          inputName: "timeStart",
        },
        {
          label: "Heure de fin",
          type: "time",
          inputName: "timeEnd",
        },
      ],
      disabledDates: {
        to: this.$moment().subtract(1, "d").toDate(),
      },
    };
  },
  props: {
    title: {},
    event: {},
    category: {},
    fxback: {},
    fxsave: {},
    fxdelete: {},
    disabled: { default: false },
    slotMaxTime: {},
    slotMinTime: {},
  },
  components: {
    Datepicker,
    VueTimepicker,
    loader,
  },
  watch: {
    getTitle(val) {
      if (val === undefined) {
        this.validationMessage = "le nom de l'évenement est obligatoire";
      }
    },
  },
  mounted() {
    this.localDisabled = this.disabled;
    this.displayButton = this.disabled;
    this.temporaryEvents = JSON.parse(JSON.stringify(this.event));
    if (this.event.title) {
      this.isEditing = true;
      this.localDisabled = true;
    }
    if (this.temporaryEvents["title"])
      this.temporaryEvents["title"] = this.temporaryEvents["titleFormat"].title;
    if (this.temporaryEvents["urgent"])
      this.temporaryEvents["urgent"] =
        this.temporaryEvents["urgent"] === "true";
    this.$set(this.timeError, "timeEnd", { color: "transparent" });
    this.$set(this.timeError, "timeStart", { color: "transparent" });
    this.setClientInformation(false);
  },
  computed: {
    getCategoryKeys() {
      return Object.keys(this.category);
    },
    getTitle() {
      if (this.temporaryEvents["title"]) return this.temporaryEvents["title"];
      return undefined;
    },
    timeIsValide() {
      for (const value of Object.values(this.timeError)) {
        if (value.color === "red") return false;
      }
      return true;
    },
    allFielsAreFilled() {
      for (const [key, value] of Object.entries(this.temporaryEvents)) {
        if (key !== "veloNumber" && key !== "clientInformation") {
          if (value === undefined || value === null || value.length === 0) {
            return false;
          }
        }
      }
      return true;
    },
  },
  methods: {
    itsFirst(value, idx) {
      if (value === undefined && idx === 0) return "none";
      return "block";
    },
    verifVeloNumber(data, value) {
      if (data.inputName === "veloNumber" && value && value.length > 0) {
        this.setClientInformation(false);
      }
    },
    setClientInformation(close) {
      let that = this;
      if (
        this.temporaryEvents["veloNumber"] !== undefined &&
        this.temporaryEvents["veloNumber"] !== null &&
        this.temporaryEvents["veloNumber"] !== "" &&
        this.temporaryEvents["veloNumber"].length > 0
      ) {
        this.isLoading = true;
        engine.get_client_info_by_id(
          that.temporaryEvents["veloNumber"],
          function (data) {
            if (data.code === 0) {
              that.clientInformation = data.data.data[0];
              that.temporaryEvents["clientInformation"] =
                data.data.data[0].fieldData;
              let id =
                that.temporaryEvents["clientInformation"][
                  "Clients::adres MAIL"
                ] +
                "_" +
                that.temporaryEvents["clientInformation"]["N° MATR INT_UID"] +
                "_" +
                that.temporaryEvents["category"] +
                "_" +
                that
                  .$moment(that.temporaryEvents["dateStart"])
                  .format("DD-MM-YYYY") +
                "_" +
                that.temporaryEvents["timeStart"] +
                "_" +
                that.temporaryEvents["timeEnd"];

              // that.temporaryEvents['veloNumber'] = that.temporaryEvents['veloNumber'].toUpperCase()
              engine.get_client_email_verification(id, function (data) {
                if (data.code === 0) {
                  that.emailSended = true;
                } else {
                  that.emailSended = false;
                }
              });
            } else {
              that.temporaryEvents["clientInformation"] = null;
            }
            if (that.temporaryEvents.titleFormat)
              that.temporaryEvents.titleFormat.title =
                that.temporaryEvents.title;
            if (close) that.fxsave(that.temporaryEvents);
            that.isLoading = false;
          }
        );
      }
    },
    timePickerEror(e, key) {
      let minTime = this.slotMinTime.split(":");
      minTime = { h: parseInt(minTime[0]), m: parseInt(minTime[1]) };
      let maxTime = this.slotMaxTime.split(":");
      maxTime = { h: parseInt(maxTime[0]), m: parseInt(maxTime[1]) };
      let alertMsg =
        "Les heures d'ouvertures sont entre " +
        this.slotMinTime +
        " et " +
        this.slotMaxTime;
      if (
        this.$moment(this.temporaryEvents["dateStart"]).format("ddd") === "Sat"
      ) {
        minTime = { h: 8, m: 30 };
        maxTime = { h: 12, m: 0 };
        alertMsg =
          "Les évenements doivent être compris entre 8h30 et 12h les samedis.";
      }

      let time = e.split(":");
      let currentTime = { h: parseInt(time[0]), m: parseInt(time[1]) };

      if (key === "timeStart") {
        let timeEnd = this.temporaryEvents["timeEnd"].split(":");
        timeEnd = { h: parseInt(timeEnd[0]), m: parseInt(timeEnd[1]) };
        if (
          timeEnd.h < currentTime.h ||
          (timeEnd.h === currentTime.h && timeEnd.m < currentTime.m)
        ) {
          let minute = currentTime.m;
          if (currentTime.m < 10) minute = "0" + currentTime.m;
          this.temporaryEvents["timeEnd"] = currentTime.h + 1 + ":" + minute;
        }
      }

      this.validationMessage = "Enregistrer";
      if (
        currentTime.h < minTime.h ||
        (currentTime.h === minTime.h && currentTime.m < minTime.m)
      ) {
        this.timeError[key]["color"] = "red";
        this.timeError[key].msg = alertMsg;
        this.validationMessage = "Erreur dans l'heure de l'évenement";
      } else if (
        currentTime.h > maxTime.h ||
        (currentTime.h === maxTime.h && currentTime.m > maxTime.m)
      ) {
        this.timeError[key]["color"] = "red";
        this.timeError[key].msg = alertMsg;
        this.validationMessage = "Erreur dans l'heure de l'évenement";
      } else {
        if (this.getTitle === undefined) {
          this.validationMessage = "le nom de l'évenement est obligatoire";
        }
        this.timeError[key]["color"] = "transparent";
        this.timeError[key].msg = undefined;
      }
    },
    ctrlback: function () {
      if (this.isLoading === false) this.fxback();
    },
    ctrlsave: function () {
      if (
        this.temporaryEvents["veloNumber"] &&
        this.temporaryEvents["veloNumber"] !== null &&
        this.temporaryEvents["veloNumber"].length !== 0
      ) {
        this.setClientInformation(true);
      } else {
        if (this.temporaryEvents.titleFormat)
          this.temporaryEvents.titleFormat.title = this.temporaryEvents.title;
        this.fxsave(this.temporaryEvents);
        this.isLoading = false;
      }
    },
    ctrldelete: function () {
      this.fxdelete(this.temporaryEvents);
    },
  },
};
</script>

import Vue from 'vue'
import App from './App.vue'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'

import login from "@/pages/page_login"
import calendar from "@/pages/page_calendar"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

library.add(faUser, faLock, faSignOutAlt, faSyncAlt)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueResource)
Vue.use(VueTimepicker)
Vue.use(require('vue-moment'));
Vue.config.productionTip = false

const store = new Vuex.Store({
    state: {
        user: { name: undefined },
    },
    mutations: {
        setUser(state, userName) {
            state.user.name = userName
        }
    },
    plugins: [new VuexPersistence().plugin]
})

const routes = [{
        path: '*',
        name: 'calendar',
        component: calendar
    },
    {
        path: '/login',
        name: 'login',
        component: login
    },
]

const router = new VueRouter({
    routes
})

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')
<template>
  <div align="center" style="margin-top: 30px">
    <drawerRight v-if="drawerIsOpen" :title="'Gestion des évenements'" :event="selectedEvent" :fxback="closeDrawer"
      :fxsave="saveDrawer" :fxdelete="deleteEvent" :drawerIsOpen="drawerIsOpen" :category="category" :disabled="disabled"
      :slotMinTime="slotMinTime" :slotMaxTime="slotMaxTime" />
    <gestionDrawerRight v-if="gestionDrawerIsOpen" :title="'Gestion du nombre d\'évenement'" :fxback="closeDrawerGestion"
      :fxsave="saveDrawerGestion" :event="selectedEvent" />
    <div style="width: 99%">
      <div style="position: absolute; left: 20px; top: 25px">
        <div style="
                                        border: solid 1px grey;
                                        padding: 8px;
                                        border-radius: 8px;
                                        background-color: white;
                                      ">
          <h2 style="margin: 0px">
            <font-awesome-icon @click="refresh()" icon="user" style="font-size: 29px; color: #424242; cursor: pointer" />
            {{ getUserName.user }}
          </h2>
        </div>
      </div>
      <div style="position: absolute; right: 25px; top: 10px">
        <h2>
          <button style="
                                          margin: 0px 2px;
                                          padding: 5px;
                                          border-radius: 5px;
                                          border-style: none;
                                          border: solid 1px lightgrey;
                                          background-color: white;
                                        ">
            <font-awesome-icon @click="refresh()" icon="sync-alt"
              style="font-size: 29px; color: #424242; cursor: pointer" />
          </button>
          <button @click="logOut()" style="
                                          margin-left: 10px;
                                          padding: 5px;
                                          border-radius: 5px;
                                          border-style: none;
                                          border: solid 1px lightgrey;
                                          background-color: white;
                                        ">
            <font-awesome-icon icon="sign-out-alt" style="font-size: 29px; cursor: pointer; color: red" />
          </button>
        </h2>
      </div>
      <fullCalendar :options="getCalendarOptions" />
      <div v-if="errorMsg" style="
                                      width: 500px;
                                      color: white;
                                      position: absolute;
                                      bottom: 90px;
                                      left: 40%;
                                    ">
        <div style="
                                        padding: 15px;
                                        z-index: 3;
                                        position: absolute;
                                        background-color: #f44336;
                                        margin-top: 10px;
                                        border-radius: 10px;
                                      ">
          {{ errorMsg }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom";

import drawerRight from "@/components/DrawerRight";
import gestionDrawerRight from "@/components/DrawerRightWeekEdit";
import timeGridPlugin from "@fullcalendar/timegrid";
import fullCalendar from "@fullcalendar/vue";
import interactionPlugin from "@fullcalendar/interaction";
import fr from "@fullcalendar/core/locales/fr";

import engine from "@/core/data/DataAPI.js";

export default {
  components: {
    fullCalendar, // make the <FullCalendar> tag available
    drawerRight,
    gestionDrawerRight,
  },
  data() {
    return {
      calendarSettings: undefined,
      gestionDrawerIsOpen: false,
      drawerIsOpen: false,
      calendarDateStart: undefined,
      calendarDateEnd: undefined,
      eventsDateList: {},
      disabled: false,
      errorMsg: undefined,
      defaultNbrMaxEventEachDay: 1,
      slotMinTime: "09:30:00",
      slotMaxTime: "18:00:00",
      showMinTime: "08:30:00",
      showMaxTime: "18:00:00",
      category: {
        "Maintenance régulière": "#3F51B5",
        Dépannage: "#FFA726",
        "Récupération de vélo": "#FF5722",
        Restitution: "purple",
        Divers: "#795548",
      },
      selectedEvent: {},
      events: undefined,
      calendarOptions: {},
    };
  },
  created() {
    this.userIsConnected();
  },
  computed: {
    getUserName() {
      if (this.$store.state.user.name) return this.$store.state.user.name;
      else return { user: "", admin: "" };
    },
    getCalendarOptions() {
      let option = {
        titleFormat: { year: "numeric", month: "long", day: "numeric" },
        locale: fr,
        plugins: [interactionPlugin, timeGridPlugin],
        datesSet: this.updateCalendarView,
        initialView: "timeGridWeek",
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        eventDrop: this.handleEventDrop,
        events: this.events,
        height: "95vh",
        editable: false,
        hiddenDays: [0],
        slotMinTime: this.showMinTime,
        slotMaxTime: this.showMaxTime,
        eventResize: this.handleEventDragTime,
        eventDurationEditable: false,
        slotEventOverlap: false,
        headerToolbar: {
          left: "",
          center: "title",
          right: "today prev,next",
        },
      };
      return option;
    },
  },
  methods: {
    updateCalendarView(calendarData) {
      if (
        this.calendarDateStart !=
        this.$moment(calendarData.start).format("MM/DD/YYYY") ||
        this.calendarDateEnd !=
        this.$moment(calendarData.end).format("MM/DD/YYYY")
      ) {
        this.calendarDateStart = this.$moment(calendarData.start).format(
          "MM/DD/YYYY"
        );
        this.calendarDateEnd = this.$moment(calendarData.end).format(
          "MM/DD/YYYY"
        );
        this.setEvents();
        this.setEventsSettings();
      }
    },
    refresh() {
      this.setEvents();
      this.setEventsSettings();
    },
    logOut() {
      localStorage.clear();
      this.$router.push({ path: "/login" });
    },
    userIsConnected() {
      let token = localStorage.getItem("token");
      let that = this;
      engine.user_token_is_valide(token, function (data) {
        if (data.code !== 0) {
          that.$router.push({ path: "/login" });
        } else {
          that.$store.commit("setUser", data.data);
          that.setEventsSettings();
        }
      });
    },
    // drawerRight function //

    saveDrawer(newEvent) {
      let that = this;
      let eventIndex = -1;
      if (newEvent.id)
        eventIndex = this.events.findIndex((val) => val.id === newEvent.id);
      if (
        this.$moment(newEvent.dateEnd).format("LL") ===
        this.$moment(newEvent.dateStart).format("LL") ||
        this.toManyEventsInSameDay(newEvent.dateStart)
      ) {
        if (
          newEvent.category === undefined ||
          newEvent.category === "" ||
          newEvent.category === null
        )
          newEvent.category = Object.keys(this.category)[0];
        if (
          newEvent.veloNumber === undefined ||
          newEvent.veloNumber === null ||
          newEvent.veloNumber === ""
        ) {
          newEvent.veloNumber = "";
          newEvent.clientInformation = "";
        }
        let timeEnd = newEvent.timeEnd.split(":");
        let timeStart = newEvent.timeStart.split(":");
        timeEnd = { h: parseInt(timeEnd[0]), m: parseInt(timeEnd[1]) };
        timeStart = { h: parseInt(timeStart[0]), m: parseInt(timeStart[1]) };
        if (timeEnd.h < timeStart.h) {
          newEvent.timeEnd = timeStart.h + 1 + ":" + timeStart.m;
        }

        let eventFormated = this.fromCalendarFormatToTable(newEvent);
        eventFormated["event"]["Dernière modifiaction"] = this.getUserName.user;
        if (eventIndex === -1) {
          eventFormated.event["Supprimé"] = 0;
          engine.create_event(eventFormated.event, function (data) {
            if (data.code === 0) {
              that.mailVerification(newEvent);
              that.updateGestion(eventFormated);
            }
            that.setEventsSettings()
          });
        } else {
          engine.edit_event(
            eventFormated.event,
            eventFormated.id,
            function (data) {
              if (data.code === 0) {
                that.mailVerification(newEvent);
                that.updateGestion(eventFormated);
              }
              that.setEventsSettings()
            }
          );
        }
        this.drawerIsOpen = false;
      } else {
        this.showAlert(
          "Le nombre maximum d'évenement à été atteint pour le " +
          this.$moment(newEvent.dateStart).format("DD/MM/YYYY") +
          "."
        );
      }
    },
    deleteEvent(newEvent) {
      let that = this;
      let eventFormated = this.fromCalendarFormatToTable(newEvent);
      eventFormated.event["Supprimé"] = 1;
      engine.edit_event(eventFormated.event, eventFormated.id, function (data) {
        if (data.code === 0) {
          that.setEvents();
          engine.AGENDA_sup_rdv_materiel(eventFormated.id, function () { });
        }
      });
      this.drawerIsOpen = false;
    },
    closeDrawer() {
      this.drawerIsOpen = false;
      this.selectedEvent = {};
    },
    updateGestion(eventFormated) {
      console.log("updateGestion");
      let gestionData = undefined;
      let start = this.$moment(eventFormated.event["Date de début"]).format(
        "MM/DD/YYYY"
      );
      if (this.calendarSettings) {
        gestionData = this.calendarSettings.find((val) => val.date == start);
      }
      let that = this;
      console.log("gestionData", gestionData);
      if (gestionData == undefined) {
        // let defaultNumber = this.defaultNbrMaxEventEachDay
        engine.get_calendar_settings(
          start,
          start,
          function (data) {
            if (data.code === 0) {
              gestionData = that.settingsFromTableToCalendarFormat(
                data.data.data
              ).find((val) => val.date == start);
            }
            that.setEvents();
            let max = that.defaultNbrMaxEventEachDay
            if (gestionData != undefined)
              max = gestionData.nbrEventMax
            engine.set_calendar_setting(
              {
                Date: eventFormated.event["Date de début"],
                "Nombre d'intervention max": max,
                "Dernière modifiaction": that.getUserName.user,
              },
              function () {
                // if (data.code === 0) {
                that.setEventsSettings();
                // }
              }
            );
          }
        )
      } else {
        this.setEventsSettings();
      }
    },

    //////////////////////////

    // update Calendar data //

    setEvents() {
      let that = this;
      engine.get_events(
        this.calendarDateStart,
        this.calendarDateEnd,
        function (data) {
          that.events = that.fromTableToCalendarFormat(data.data.data);
        }
      );
    },
    setEventsSettings() {
      let that = this;
      engine.get_calendar_settings(
        this.calendarDateStart,
        this.calendarDateEnd,
        function (data) {
          if (data.code === 0) {
            that.calendarSettings = that.settingsFromTableToCalendarFormat(
              data.data.data
            );
          }
          that.setEvents();
        }
      );
    },

    //////////////////////////

    // gestionDrawerRight ////

    saveDrawerGestion(data) {
      // if (data) {
      let that = this;
      //   if (data.id)
      engine.edit_calendar_setting(
        {
          Date: data.date,
          "Nombre d'intervention max": data.nbrEventMax,
          "Dernière modifiaction": this.getUserName.user,
        },
        data['Clé primaire'],
        function () {
          // if (data.code === 0) {
          that.setEventsSettings();
          // }
        }
      );
      // else {
      //   engine.set_calendar_setting(
      //     {
      //       Date: data.date,
      //       "Nombre d'intervention max": data.nbrEventMax,
      //       "Dernière modifiaction": this.getUserName.user,
      //     },
      //     function (data) {
      //       if (data.code === 0) {
      //         that.setEventsSettings();
      //       }
      //     }
      //   );
      // }
      // }
      this.gestionDrawerIsOpen = false;
    },
    closeDrawerGestion() {
      this.gestionDrawerIsOpen = false;
      this.selectedEvent = {};
    },

    //////////////////////////

    // format ////////////////

    fromCalendarFormatToTable(event) {
      let id = event.id;
      let title = event.title;
      if (event.titleFormat) title = event.titleFormat.title;
      return {
        event: {
          Titre: title,
          "Client information": JSON.stringify(event.clientInformation),
          "Date de début": this.$moment(event.dateStart).format("MM/DD/YYYY"),
          "Date de fin": this.$moment(event.dateStart).format("MM/DD/YYYY"),
          // "Date de fin": this.$moment(event.dateEnd).format("MM/DD/YYYY"),
          "Heure de début": event.timeStart,
          "Heure de fin": event.timeEnd,
          Catégorie: event.category,
          "Numéro de vélo": event.veloNumber,
          Supprimé: event.deleted,
          Statut: event.state,
        },
        id: id,
      };
    },
    fromTableToCalendarFormat(events) {
      let eventsFormated = [];
      this.eventsDateList = {};
      if (events) {
        for (let i = 0; i < events.length; i++) {
          if (events[i].fieldData["Supprimé"] == 0) {
            let clientInformation = undefined;
            if (events[i].fieldData["Client information"])
              clientInformation = JSON.parse(
                events[i].fieldData["Client information"]
              );
            let dateStart = this.$moment(
              events[i].fieldData["Date de début"]
            ).format("YYYY-MM-DD");
            if (this.eventsDateList[dateStart]) {
              this.eventsDateList[dateStart] += 1;
            } else {
              this.eventsDateList[dateStart] = 1;
            }
            let state = undefined;
            if (events[i].fieldData["Statut"] === "Non Réalisé") {
              state = "";
            }
            if (events[i].fieldData["Statut"] === "Réalisé") {
              state = "✅";
            }
            if (events[i].fieldData["Statut"] === "Client Absent") {
              state = "❌";
            }
            let titleFormat = {
              title: events[i].fieldData["Titre"],
              titleToShow: state + " " + events[i].fieldData["Titre"],
            };

            if (
              events[i].fieldData["Numéro de vélo"] &&
              events[i].fieldData["Numéro de vélo"].length !== 0
            ) {
              titleFormat.titleToShow +=
                " / " + events[i].fieldData["Numéro de vélo"];
            }

            if (clientInformation && clientInformation["Clients::Société"]) {
              titleFormat.titleToShow +=
                " / " + clientInformation["Clients::Société"];
            }
            eventsFormated.push({
              id: events[i].recordId,
              deleted: events[i].fieldData["Supprimé"],
              title: titleFormat.titleToShow,
              titleFormat: titleFormat,
              clientInformation: clientInformation,
              state: events[i].fieldData["Statut"],
              veloNumber: events[i].fieldData["Numéro de vélo"],
              dateStart: dateStart,
              dateEnd: this.$moment(events[i].fieldData["Date de fin"]).format(
                "YYYY-MM-DD"
              ),
              timeStart: events[i].fieldData["Heure de début"],
              timeEnd: events[i].fieldData["Heure de fin"],
              start:
                this.$moment(events[i].fieldData["Date de début"]).format(
                  "YYYY-MM-DD "
                ) + events[i].fieldData["Heure de début"],
              end:
                this.$moment(events[i].fieldData["Date de début"]).format(
                  "YYYY-MM-DD "
                ) + events[i].fieldData["Heure de fin"],
              // end:
              //   this.$moment(events[i].fieldData["Date de fin"]).format(
              //     "YYYY-MM-DD "
              //   ) + events[i].fieldData["Heure de fin"],
              category: events[i].fieldData["Catégorie"],
              color: this.category[events[i].fieldData["Catégorie"]],
              display: "block",
            });
          }
        }
      }
      if (this.calendarSettings) {
        for (let i = 0; i < this.calendarSettings.length; i++) {
          let start = this.$moment(this.calendarSettings[i].date).format(
            "YYYY-MM-DD"
          );
          let nbrIntervention = 0;
          if (this.eventsDateList[start])
            nbrIntervention = this.eventsDateList[start];
          // if (this.eventsDateList[start]) {
          let title = "Nombre d'intervention : " +
            nbrIntervention +
            " / " +
            this.calendarSettings[i].nbrEventMax
          eventsFormated.push({
            title: title,
            start: start,
            color: "white",
            backgroundColor: "grey",
            allDay: true,
            editable: false,
            type: "gestion",
          });
          if (nbrIntervention >= this.calendarSettings[i].nbrEventMax) {
            eventsFormated.push({
              start: start,
              color: "#D50000",
              display: "background",
              allDay: true,
            });
          }
          // }
        }
      }
      eventsFormated.push({
        daysOfWeek: [6],
        startTime: "12:00",
        endTime: "23:59",
        color: "black",
        display: "background",
      });
      for (let i = 0; i <= 5; i++) {
        eventsFormated.push({
          daysOfWeek: [i],
          startTime: "00:00",
          endTime: "9:30",
          color: "black",
          display: "background",
        });
      }
      return eventsFormated;
    },
    settingsFromTableToCalendarFormat(settings) {
      let settingsFormatted = [];

      if (settings) {
        for (let i = 0; i < settings.length; i++) {
          settingsFormatted.push({
            date: settings[i].fieldData["Date"],
            nbrEventMax: settings[i].fieldData["Nombre d'intervention max"],
            'Clé primaire': settings[i].fieldData["Clé primaire"],
          });
        }
      }
      return settingsFormatted;
    },

    //////////////////////////

    // Event interaction /////

    handleEventDragTime(e) {
      let that = this;
      let dropedEvent = this.events.find(
        (val) => val.id == e.event._def.publicId
      );
      if (
        this.dateIsInPaste(
          this.$moment(e.event.startStr).format("YYYY-MM-DD")
        ) &&
        this.eventInTimeRange(e.event)
      ) {
        dropedEvent.timeEnd = this.$moment(e.event.endStr).format("HH:mm");
        let eventFormated = this.fromCalendarFormatToTable(dropedEvent);
        engine.edit_event(eventFormated.event, eventFormated.id, function () {
          that.setEvents();
        });
      } else {
        this.setEvents();
      }
    },
    handleEventDrop(e) {
      let that = this;
      let dropedEvent = this.events.find(
        (val) => val.id == e.event._def.publicId
      );
      if (
        this.dateIsInPaste(
          this.$moment(e.event.startStr).format("YYYY-MM-DD")
        ) &&
        this.dateIsInPaste(dropedEvent.dateStart) &&
        (this.$moment(e.event.startStr).format("LL") ===
          this.$moment(dropedEvent.dateStart).format("LL") ||
          this.toManyEventsInSameDay(e.event.startStr)) &&
        this.eventInTimeRange(e.event)
      ) {
        dropedEvent.dateStart = this.$moment(e.event.startStr).format(
          "YYYY-MM-DD"
        );
        dropedEvent.dateEnd = this.$moment(e.event.endStr).format("YYYY-MM-DD");
        dropedEvent.timeStart = this.$moment(e.event.startStr).format("HH:mm");
        dropedEvent.timeEnd = this.$moment(e.event.endStr).format("HH:mm");
        let eventFormated = this.fromCalendarFormatToTable(dropedEvent);
        engine.edit_event(
          eventFormated.event,
          eventFormated.id,
          function (data) {
            if (data.code === 0) {
              that.updateGestion(eventFormated);
            }
          }
        );
      } else {
        this.setEvents();
      }
    },
    handleEventClick(e) {
      if (
        e.event._def.extendedProps["type"] &&
        e.event._def.extendedProps["type"] === "gestion" &&
        this.dateIsInPaste(e.event.start, false)
      ) {
        let clickedDate = this.$moment(e.event.start).format("MM/DD/YYYY");
        let data = this.calendarSettings.find(
          (val) => val.date === clickedDate
        );
        if (clickedDate) {
          this.selectedEvent = data;
        }
        if (this.getUserName.admin === "Oui") this.gestionDrawerIsOpen = true;
      } else {
        if (this.dateIsInPaste(e.event._def.extendedProps.dateStart, false))
          this.disabled = false;
        else this.disabled = true;
        this.selectedEvent = this.events.find(
          (val) => val.id == e.event._def.publicId
        );
        if (this.selectedEvent) {
          if (this.selectedEvent.start && this.selectedEvent.end) {
            this.selectedEvent["dateStart"] =
              this.selectedEvent.start.split(" ")[0];
            this.selectedEvent["timeStart"] =
              this.selectedEvent.start.split(" ")[1];
            this.selectedEvent["timeEnd"] =
              this.selectedEvent.end.split(" ")[1];
          }
          this.drawerIsOpen = true;
        }
      }
    },
    handleDateClick(arg) {
      if (arg.allDay && this.dateIsInPaste(arg.dateStr)) {
        let clickedDate = this.$moment(arg.dateStr).format("MM/DD/YYYY");
        this.selectedEvent = { date: clickedDate };
        if (this.getUserName.admin === "Oui") this.gestionDrawerIsOpen = true;
      } else if (
        this.dateIsInPaste(arg.dateStr) &&
        this.toManyEventsInSameDay(arg.dateStr)
      ) {
        let new_event = {};
        new_event["dateStart"] = arg.dateStr;
        // new_event["dateEnd"] = arg.dateStr;
        new_event["timeStart"] = this.$moment(arg.dateStr).format("HH:mm");
        new_event["timeEnd"] = this.$moment(arg.dateStr)
          .add(1, "hours")
          .format("HH:mm");
        new_event["title"] = null;
        new_event["veloNumber"] = null;
        new_event["category"] = undefined;
        new_event["state"] = "Non Réalisé";
        this.selectedEvent = new_event;
        this.disabled = false;
        this.drawerIsOpen = true;
      }
    },

    //////////////////////////

    // Data verification /////

    eventInTimeRange(event) {
      let minTime = this.slotMinTime.split(":"); // { h: 9, m: 30 };
      minTime = { h: parseInt(minTime[0]), m: parseInt(minTime[1]) };
      let maxTime = this.slotMaxTime.split(":"); //{ h: 18, m: 30 };
      maxTime = { h: parseInt(maxTime[0]), m: parseInt(maxTime[1]) };

      let alertMsg = "Les évenements doivent être compris entre 9h30 et 18h";

      if (this.$moment(event.start).format("ddd") === "Sat") {
        minTime = { h: 8, m: 30 };
        maxTime = { h: 12, m: 0 };
        alertMsg =
          "Les évenements doivent être compris entre 9h30 et 12h les samedis.";
      }

      let currentMinTime = this.$moment(event.start).format("HH:mm").split(":");
      let currentMaxTime = this.$moment(event.end).format("HH:mm").split(":");
      currentMinTime = {
        h: parseInt(currentMinTime[0]),
        m: parseInt(currentMinTime[1]),
      };
      currentMaxTime = {
        h: parseInt(currentMaxTime[0]),
        m: parseInt(currentMaxTime[1]),
      };
      if (
        currentMinTime.h < minTime.h ||
        (currentMinTime.h === minTime.h && currentMinTime.m < minTime.m) ||
        currentMaxTime.h > maxTime.h ||
        (currentMaxTime.h === maxTime.h && currentMaxTime.m > maxTime.m)
      ) {
        this.showAlert(alertMsg);
        return false;
      }
      return true;
    },
    toManyEventsInSameDay(date) {
      let formatedDate = this.$moment(date).format("MM/DD/YYYY");
      let selectedDay = undefined;
      if (this.calendarSettings) {
        selectedDay = this.calendarSettings.find(
          (val) => val.date == formatedDate
        );
      }
      let currentNumber =
        this.eventsDateList[this.$moment(date).format("YYYY-MM-DD")];
      if (currentNumber === undefined) currentNumber = 0;
      if (
        selectedDay !== undefined &&
        currentNumber !== undefined &&
        currentNumber >= selectedDay.nbrEventMax
      ) {
        this.showAlert(
          "Le nombre maximum d'évenement à été atteint pour le " +
          this.$moment(formatedDate).format("DD/MM/YYYY") +
          "."
        );
        return false;
      }
      return true;
    },
    dateIsInPaste(date, display) {
      if (this.$moment(date).add(1, "d").isBefore()) {
        if (display === undefined)
          this.showAlert("Impossible d'interagir avec un événement passé.");
        return false;
      } else {
        return true;
      }
    },

    //////////////////////////

    // Alert /////////////////

    showAlert(msg) {
      let that = this;
      this.errorMsg = msg;
      setTimeout(function () {
        that.errorMsg = undefined;
      }, 3000);
    },

    //////////////////////////

    // Send email ////////////

    mailVerification(event) {
      if (
        event.clientInformation &&
        event.clientInformation["Clients::adres MAIL"].length > 0
      ) {
        let client = event.clientInformation;
        let id =
          client["Clients::adres MAIL"] +
          "_" +
          client["N° MATR INT_UID"] +
          "_" +
          event["category"] +
          "_" +
          this.$moment(event["dateStart"]).format("DD-MM-YYYY") +
          "_" +
          event["timeStart"] +
          "_" +
          event["timeEnd"];
        let that = this;
        engine.get_client_email_verification(id, function (data) {
          if (data.code === 0) {
            console.log("Email déja envoyé");
          } else {
            let RDV = "";
            if (event.category === Object.keys(that.category)[0]) {
              RDV = "Oui";
            }
            let newEmail = {
              Email: client["Clients::adres MAIL"],
              // Email: "christian.betta@accen.fr",
              "Numéro de vélo": client["N° MATR INT"],
              "Email Id": id,
              RDV: RDV,
              RDV_date: that.$moment(event["dateStart"]).format("MM/DD/YYYY"),
            };
            engine.creat_email_verification(newEmail, function (data) {
              if (data.code === 0) {
                let body = newEmail;
                let recordId = data.data.recordId;
                engine.send_email(
                  recordId,
                  body,
                  event,
                  that.$moment().format("DD/MM/YYYY"),
                  function (data) {
                    if (data.code === 0) {
                      engine.AGENDA_maj_rdv_materiel(id, function () { });
                    }
                  }
                );
              }
            });
          }
        });
      } else {
        this.showAlert(
          "Information client incomplète envoie d'email impossible."
        );
      }
    },

    //////////////////////////
  },
};
</script>
<style></style>